$block: '.tag-status-group-btn';

#{$block} {
	padding: 4px;
  background-color: #0000000d;
  border-radius: 8px;

  & .g-button_selected {
      --_--background-color: #ffffff;
      --_--text-color: #000000;
  }
}