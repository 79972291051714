$block: '.qr-page';

#{$block} {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 66px);
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 56px;

  &__qr-form {
    // box-shadow: 0px 4px 20px 0px #1018281F;
    max-width: 605px;
    min-width: 605px;
    width: 100%;
    // height: 315px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 32px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__qr-count-input {
    & .g-text-input__content {
      padding: 6px 4px 6px 4px;
      --_--border-color: #2AD162;
      --_--border-color-hover: #2cac56;
      --_--border-color-active: #2AD162;
    }

    & .g-text-input__additional-content {
      padding-inline-end: 0;
    }
  }

  &__qr-count-input-btn {
    --_--background-color: #2AD162;
    --_--background-color-hover: #2cac56;
    --_--text-color: white;

    & .g-button__text {
      display: flex;
      justify-content: center;
      align-items: center;
  
      & svg {
        margin-left: 12px;
      }
    }
  }

  & .input-label-wrapper {
    margin-bottom: 12px;
  }

  & .fs13-secondary-thin {
    margin-bottom: 4px;
  }

  & .fs48-primary-bold {
    margin-bottom: 32px;
  }

  & .react-multi-carousel-list {
    padding: 16px 0;
    
    & .react-multi-carousel-track {
      gap: 8px;

      & .react-multi-carousel-item {
        width: max-content !important;
        cursor: pointer;
      }
    }
  }
}
