$block: '.gen-manage-page';

#{$block} {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - 66px);
  background-color: #ffffff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: auto;

  & .g-label {
    border-radius: 12px;
  }
}
