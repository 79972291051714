$block: '.condition-modal';

#{$block} {
  min-width: 180px;
  max-width: 300px;

  & .fs17-primary-bold {
    padding: 32px 32px 16px 32px; 
    text-align: center;
  }

  & .primary-button.g-button {
    background: #2AD162;
  
    color: #ffffff;
    border-radius: 12px;
  
    &.g-button_disabled {
      background: #0000000d;
      color: #0000004d;
    }
  
    &.g-button_loading {
      background: #2AD162;
    }
  }
}

.modal.g-modal {
  backdrop-filter: blur(10px);
  background-color: #0000008C;
}
