$block: '.app-bar';

#{$block} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    padding: 12px 32px;
    padding-bottom: 0;
    padding-top: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-bottom: 4px;
    min-height: 64px;

    &__version.g-label {
        border-radius: 12px;
        position: absolute;
        left: 100%;
    }
 
    &__logo-wrapper {
        width: 112px;
        height: 28px;
    }

    &__logo-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__user-setting {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    & .g-tabs {
        height: 100%;
    }

    & .g-tabs_direction_horizontal .g-tabs__item {
        height: 100%;
    }

    & .g-tabs_direction_horizontal .g-tabs__item_active, 
    & .g-tabs_direction_horizontal .g-tabs__item_active:hover {
        border-color: #000000D9;
    }
}
