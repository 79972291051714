$block: '.img-wrapper';

#{$block} {
  overflow: hidden;
  border: 2px solid transparent;
  border-radius: 12px;

  &_select {
    border: 2px solid black;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}